







































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

import Axios from "axios";

@Component({ components: {  } })
export default class CustomVueNumeric extends Vue {
   public errorName=null;
   public isEmpty=false;
    @Prop()
    public datavvas;
    @Prop()
    public origin;
    @Prop()
    public type;
    @Prop()
    public inputClass;
    @Prop()
    public name;
    @Prop()
    public disabled;
    @Prop()
    public vmodel;
    @Prop()
    public validate;
   @Prop()
   public precision
   @Prop()
   public greaterPrice
   @Prop()
   public greaterInterest
    public async validateAll() {
        let valid = await this.$validator.validateAll();
        if (!valid) {
        return false;
        } else {
        return true;
        }
    }
    public emptyError(){
        this.errorName = null;
        this.isEmpty = false;
        this.validateAll(); 
    }

    public showError(){
        this.errorName = this.name; 
        this.isEmpty = false;
        this.validateAll(); 
        
        
    }
   public getValue(){
       this.errorName = this.name;
       this.isEmpty = true;
       this.validateAll();
   }
  mounted() {  
  }
}
