<template src="./LoanDocuments.html"></template>
<script>
import LoanDocumentsSmart from './LoanDocumentsSmart.vue'
import LoanDocumentsNonSmart from './LoanDocumentsNonSmart.vue'
import { BASE_API_URL } from "@/config";
import Axios from "axios";

export default {
  name: 'LoanDocuments',
  components: {
    LoanDocumentsSmart, LoanDocumentsNonSmart
  }, 
  methods: {
    getBorrowerLoanDocuments: async function() {
      try {
        const loanTxnId = this.$route.query.id;
        let response = await Axios.post(
          BASE_API_URL + "document/fetchBorrowerDocuments/",
          { loanTxnId: loanTxnId });
        if(response.data.version == '1.0'){
          this.component = 'LoanDocumentsSmart'
        }else{
          this.component = 'LoanDocumentsNonSmart'
        }
      } catch (error) {
      }
    }
  },
  data (){
    return {
      component:""
    }
  },
  mounted(){
    this.getBorrowerLoanDocuments();
  }
}
</script>
