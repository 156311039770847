

























































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class DtiAndHousingRatioDetails extends Vue {
  @Prop()
  public data;

  closeModal(){
    this.$modal.hide('modalForDtiRatio');
    this.$emit("hideModal")
  }

  getTotalExpense(){
    let totalExpense:any = parseFloat(this.data.proposedExpense) + parseFloat(this.data.maintenanceExpenseAmount) +  parseFloat(this.data.liabilities);
    totalExpense = parseFloat(totalExpense).toFixed(2);
    return totalExpense;
  }

  getTotalIncome(){
    return parseFloat(this.data.rentalIncomeGrossAmount) + parseFloat(this.data.grossMonthlyIncome);
  }

  roundOffValues(){
    this.data.liabilities = parseFloat(this.data.liabilities.toFixed(2));
    this.data.rentalIncomeGrossAmount = parseFloat(this.data.rentalIncomeGrossAmount.toFixed(2));
    this.data.grossMonthlyIncome = parseFloat(this.data.grossMonthlyIncome.toFixed(2));
    // this.data.proposedExpense = parseFloat(this.data.proposedExpense.toFixed(2));
    this.data.maintenanceExpenseAmount = parseFloat(this.data.maintenanceExpenseAmount.toFixed(2));
  }

  mounted() {
    this.roundOffValues();
    this.$modal.show("modalForDtiRatio");
  }
}
