












import { Component, Vue, Prop } from 'vue-property-decorator';
import Axios from 'axios';
import { BASE_API_URL } from '@/config';
import { StateLicense } from '@/models/state-license.model';
import _ from "lodash";


interface State {
  code: string;
  name: string;
}

@Component({})
export default class LoanStateWarning extends Vue {

    @Prop() loanState: State;

    stateLicenses: Array<StateLicense> = [];

    get state() {
        return _.get(this, 'loanState');
    }

    get hasStateLicense() {
        if (!this.stateLicenses.length || !_.get(this, 'state.name')) {
            return true;
        }

        return this.stateLicenses.map(stateLicense => stateLicense.state).includes(_.get(this, 'state.code')) || !this.state;
    }

    async getStateLicenses() {
        const { data: { stateLicenses } } = await Axios.post( `${BASE_API_URL}broker/getStateLicenses`, {}) || {};

        this.stateLicenses = stateLicenses;

        setTimeout(function() {
            this.$emit('hasStateLicense', this.hasStateLicense);
        }.bind(this), 1000);
    }

    async mounted() {
        await this.getStateLicenses();
    }
}
