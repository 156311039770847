














































import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

import { HollowDotsSpinner } from "epic-spinners";
import Axios from "axios";
@Component({ components: { HollowDotsSpinner } })
export default class UserDeactivate extends Vue {
  @Prop()
  public obj: any;

  public selectedBorrowerId:any = null;
  public isSelecting = false;

  public getBorrowerName(borrowerIndex) {
    let name = null;
    if(this.obj.borrowerInfo[borrowerIndex].personalInfo)
      name = this.obj.borrowerInfo[borrowerIndex].personalInfo.name;
    else
      name = this.obj.borrowerInfo[borrowerIndex].name ? this.obj.borrowerInfo[borrowerIndex].name : this.obj.borrowerInfo[borrowerIndex];
    return name.firstName + " " + name.lastName;
  }

  public closeModal() {
    this.$modal.hide("selectBorrower");
    this.$emit("closeBorrowerSelectionModal");
  }

  public async gotToPos() {
    let valid = await this.$validator.validateAll();
    if(!valid) return;
    this.isSelecting = true;
    this.$router.push({
      path: this.$getCurrentUserTypePath("pos-questionnaire"),
      query: {
        loanTxnId: this.obj.loanTxnId,
        borrowerId: this.selectedBorrowerId
      }
    });
    this.closeModal();
    this.isSelecting = false;
  }

  mounted() {
    this.$modal.show("selectBorrower");
  }
}
